import axios from 'rewart-frontend-library/src/plugins/axios';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import vuetify from '@/plugins/vuetify';

Vue.use(VueI18n);

const loadedLocales = {};

function loadLocaleMessages(locale) {
  return loadedLocales[locale] ? Promise.resolve() : Promise.all([
    import(
      /* webpackChunkName: "app-locale-[request]" */
      /* webpackInclude: /(en|hu).json$/ */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      `@/locales/${locale}.json`),
    import(
      /* webpackChunkName: "vuetify-locale-[request]" */
      /* webpackInclude: /(en|hu).js$/ */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      `vuetify/lib/locale/${locale}.js`).then((v) => v.default),
  ]).then(([msg, vuetify]) => (loadedLocales[locale] = true) && i18n.setLocaleMessage(locale, {...msg, $vuetify: vuetify}));
}

let fallbackLoaded = false;
let fallbackLoading = false;

const i18n = new VueI18n({
  locale: null,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {},
  silentFallbackWarn: true,
  missing(locale) {
    if (!fallbackLoaded && !fallbackLoading && locale !== i18n.fallbackLocale) {
      fallbackLoading = true;
      loadLocaleMessages(i18n.fallbackLocale).then(() => fallbackLoaded = true);
    }
  },
});

export const switchLocale = (locale = process.env.VUE_APP_I18N_LOCALE || 'en') => {
  const lang = locale.slice(0, 2);

  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve();
  }
  return loadLocaleMessages(lang).then(() => {
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
    i18n.locale = lang;
    vuetify.framework.lang.current = locale;
  });
};

export default i18n;
