
import TestTools from 'rewart-frontend-library/src/top-level/TestTools';

import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';
import CookieConsent from '@/components/CookieConsent';


const social = require('../social.json');

export default {
  components: {
    CookieConsent,
    TestTools,
    Main,
    Header,
    Footer,
  },
  metaInfo() {
    return {
      meta: [
        ...(social.facebook.enabled ? [
          {
            vmid: 'og:url',
            property: 'og:url',
            content: (APP_URL || document.location.origin) + this.$route.fullPath,
          },
          {vmid: 'og:type', property: 'og:type', content: 'website'},
          {
            vmid: 'og:image',
            property: 'og:image',
            content: (APP_URL || document.location.origin) + (social.facebook.image || social.image),
          },
          {vmid: 'og:title', property: 'og:title', content: social.title || process.env.VUE_APP_TITLE},
          {
            vmid: 'og:description',
            property: 'og:description',
            content: social.facebook.description || social.description,
          },
          {vmid: 'og:site_name', property: 'og:site_name', content: social.site_name || process.env.VUE_APP_TITLE},
          {vmid: 'og:locale', property: 'og:locale', content: this.$i18n.locale},
        ] : []),
        ...(social.twitter.enabled ?
          [
            {vmid: 'twitter:card', name: 'twitter:card', content: 'summary'},
            {
              vmid: 'twitter:image',
              name: 'twitter:image',
              content: (APP_URL || document.location.origin) + social.twitter.image || social.image,
            },
            {vmid: 'twitter:site', name: 'twitter:site', content: social.twitter.site_account},
            {vmid: 'twitter:creator', name: 'twitter:creator', content: social.twitter.individual_account},
            {vmid: 'twitter:url', name: 'twitter:url', content: APP_URL},
            {vmid: 'twitter:title', name: 'twitter:title', content: process.env.VUE_APP_TITLE},
            {
              vmid: 'twitter:description',
              name: 'twitter:description',
              content: social.twitter.description || social.description,
            },
          ] : []),
      ],
    };
  },
  data() {
    return {
      sectionObserver: null,
      sections: null,
      title: null,
      date: new Date(),
    };
  },
  computed: {
    pageTitle() {
      return (this.title ? this.title + ' - ' : this.$route.meta && this.$route.meta.title ? this.$route.meta.title + ' - ' : '') + process.env.VUE_APP_TITLE;
    },
    showHeader() {
      return (!this.$route.meta || this.$route.meta.header !== false);
    },
    showFooter() {
      return (!this.$route.meta || this.$route.meta.footer !== false);
    },
  },
  watch: {
    $route() {
      this.title = null;
      this.$nextTick(this.observeSections);
    },
    pageTitle: {
      handler() {
        this.$nextTick(this.setTitle);
      },
      immediate: true,
    },
  },
  mounted() {
    this.observeSections();
    if ('__PRERENDER_INJECTED' in window) {
      this.$el.setAttribute('data-server-rendered', true);
    }
  },
  beforeDestroy() {
    this.stopObserver();
  },
  methods: {
    setTitle() {
      document.title = this.pageTitle;
    },
    stopObserver() {
      if (this.sectionObserver) {
        this.sectionObserver.disconnect();
      }
    },
    observeSections() {
      this.stopObserver();

      const options = {
        rootMargin: '0px 0px',
        threshold: [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1],
      };
      this.sectionObserver = new IntersectionObserver(this.sectionObserverHandler, options);

      // Observe each section
      const sections = document.querySelectorAll('section[id]');
      this.sections = Array.from(sections);
      sections.forEach(section => {
        this.sectionObserver.observe(section);
      });
    },
    sectionObserverHandler(entries) {
      for (const entry of entries) {
        entry.target._intersectRatio = entry.intersectionRatio;
      }
      const mostVisibleSection = this.sections.reduce((prev, current) => {
        if (current._intersectRatio > (prev ? prev._intersectRatio : 0)) {
          return current;
        } else {
          return prev;
        }
      }, null);
      if (mostVisibleSection) {
        let sectionId = mostVisibleSection.id;
        if (sectionId === 'top') {
          sectionId = '';
        } else {
          sectionId = '#' + sectionId;
        }
        // Push sectionId to router here
        const prevName = this.$route.name;
        this.$nextTick(() => {
          if (this.$router.history.pending === null && this.$route.hash !== sectionId && prevName === this.$route.name) {
            this.$router.replace({
              name: prevName,
              hash: sectionId === '' ? undefined : sectionId,
              params: {noScroll: true},
              query: this.$route.query,
            }).catch(() => {
            });
          }
        });
      }
    },
  },
};
