
const
  SET_EXPIRE_DAY = 30,
  NECESSARY = 'necessary',
  FUNCTIONAL = 'functional',
  TARGETING = 'targeting',
  CNAME = 'cookie_consent',
  BTN_ALL = 1,
  BTN_REFUSE = 2,
  BTN_CUSTOM = 3;


const minutes = 1;
const hours = 60;
const days = hours * 24;
const months = days * 30;
const years = days * 365;


export default {
  name: 'CookieConsent',
  i18n: {
    messages: {
      en: {
        title: 'Cookie Settings',
        description: 'This website uses cookies. A cookie is a small text file in the source code of the ' +
          'website that allows the website to send information to the user\'s browsing device or browser and to access ' +
          'the data stored by the cookie on the user\'s browsing device. Cookies allow the website to "remember" you ' +
          'during a single visit to the site (session cookies) or during repeated visits (persistent cookies).',
        more_info: 'For more information on how cookies work, see the {link}.',
        cookie_pdf: 'Cookie Notice',
        cookie_page: 'Cookie Notice page',
        accept_all: 'Accept all',
        reject_all: 'Reject all',
        accept_settings: 'Accept settings',
        sections: {
          necessary: {
            title: 'Cookies strictly necessary for the functioning of the website',
            description: 'These cookies ensure the proper functioning of the website and facilitate its use. ' +
              'Without the use of these cookies, parts of the website will not function properly ' +
              'and therefore these cookies cannot be disabled.',
          },
          functional: {
            title: 'Functional cookies',
            description: 'recording your individual preferences (language of the website, number of search ' +
              'results to be displayed, cookie settings, etc.). These cookies require your consent to use. You can ' +
              'turn them on/off or you can edit your preferences any time by opening the settings from the {link}. ' +
              'You can use the slider to give your consent.',
          },
          targeting: {
            title: 'Cookies for personalised advertising',
            description: 'These cookies store information about user\'s behaviour by tracking browsing habits ' +
              'and history (e.g. frequently visited websites, time spent on preferred websites), which enables the ' +
              'display of personalised advertisements from third parties. These cookies require your consent to use.' +
              'You can turn them on/off or you can edit your preferences any time by opening the settings from the {link}. ' +
              'You can use the slider to give your consent.',
          },
        },
        name: 'Name',
        type: 'Type',
        purpose: 'Purpose and processed data',
        lifetime: 'Lifetime',
        third_party: 'Third party?',
        yes: 'Yes',
        no: 'No',
        types: {
          session: 'Session',
          persistent: 'Persistent',
        },
        life: {
          year: '{count} year | {count} years',
          month: '{count} month | {count} months',
          day: '{count} day | {count} days',
          hour: '{count} hour | {count} hours',
          minute: '{count} minute | {count} minutes',
          session: 'Until the end of the Session',
          na: 'No information available',
        },
        cookies: {
          'ajs_anonymous_id': 'Allows Segment to securely identify user\'s browsers.',
          'cookie_consent': 'Storing information about ads potentially viewed by the user before visiting the site.',
          'XSRF-TOKEN': 'The cookie does not contain any personal data about the user, but it helps to check that no changes are made during the session without the user\'s consent (so-called \'hijacking\') - for example, when a form needs to be submitted on a website.',
          'NID': 'Storing the user is preferences and other browsing information and unique identifier, such as preferred language, the number of search results to display per page (e.g. 10 or 20) and whether Google Safe Search filtering is enabled.',
          '_GRECAPTCHA': 'This cookie is placed by MOL on the website is login interface and any forms you may use. Its purpose is to reduce the risk of malicious users and robots "spamming" these interfaces and forms, for example by creating invalid registrations.',
          '_ga': 'Evaluating the purpose of the user is visit and generating reports on website activity to improve the browsing experience.',
          '_gid': 'Updating and refreshing previously stored data',
          'OTZ': 'Displaying personalised ads on Google sites based on previous searches and page views.',
          'GAD': 'Gemius advertising cookie, belongs to molkupa.hu, used to load the advertising banner (the interface on the website where the advertisement is displayed), which measures clicks.',
          'Joomla': 'Joomla language management.',
          'komadadditive_session': 'Cookie strictly necessary for the functioning of the website.',
          'laravel_session': 'The cookie does not contain any personal data about the user, but it helps the user to browse the site safely. For example: it reduces the risk of a third-party stealing data from the user or sending a message on the user is behalf.',
          'molgroup_session': 'Cookie strictly necessary for the functioning of the website.',
          'molgroupchemicals_session': 'Cookie strictly necessary for the functioning of the website.',
          'Gdyn': 'The purpose of the cookie is to protect users from distracting pop-ups - for example, it helps to ensure that only one pop-up appears at a time and the next one only after a certain time, reducing the risk of the user suddenly seeing 3-4 or more pop-ups.',
          '_utma': 'Distinguishing between users and the user is browsing session (the time the browser is open), updated each time the website is opened.',
          '_utmb': 'Differentiating between users and sessions, updated each time the website is opened.',
          '_utmc': 'Filtering out new users not previously identified as visitors to the website.',
          '_utmz': 'Evaluating the purpose of the user is visit and generating reports on website activity to improve the browsing experience.',
          '__gcl_ua': 'Measuring the effectiveness of targeted advertising.',
          '1P_JAR': 'Advertising and retargeting based on the user is browsing history.',
          'gdpr': 'The website uses this cookie to store the user is cookie preferences.',
          '__fbp': 'Displaying ads to users who visit the website and are logged in to Facebook or a digital platform operated by Facebook Advertising while browsing.',
          'datr': 'Identifying the browser that is connected to Facebook. It is used for security purposes and to detect suspicious login activities, in particular robots trying to access the service.',
          'oo': 'Helps users opt-out of seeing Meta ads based on their activity on third-party websites.',
          'sb': 'Allows Meta to securely identify users browsers.',
          'anj': 'Identifying a returning user is device by recognising a previously placed cookie identifier in order to display targeted ads.',
          'uuid2': 'Distinguishing between the browsers and devices used to open the website.',
        },
        third_parties: {
          gemius: 'Gemius SA Domaniewska street 48., building D48, 6. floor Warsaw, 02-672, 48 Poland',
        },
      },
      hu: {
        title: 'Sütibeállítások',
        description: 'Ez a weboldal sütiket („cookie”) használ. A süti egy kis szövegfájl a weboldal forráskódjában, ' +
          'amelynek segítségével a weboldal információkat küld a felhasználó böngészésre használt eszközére vagy ' +
          'böngészőjébe, valamint hozzáfér a felhasználó böngészésre használt eszközén a sütik által tárolt adatokhoz. ' +
          'A sütik segítségével a weboldal „emlékezhet” Önre az oldal egyszeri látogatása során (munkamenet-sütik) vagy ' +
          'ismételt látogatások esetén (állandó sütik).',
        more_info: 'A sütik működéséről további információt talál a {link}.',
        cookie_pdf: 'Süti Tájékoztatóban',
        cookie_page: 'tájékoztató oldaláról',
        accept_all: 'Összes elfogadása',
        reject_all: 'Összes elutasítása',
        accept_settings: 'Beállítások elfogadása',
        sections: {
          necessary: {
            title: 'A weboldal működéséhez feltétlenül szükséges sütik',
            description: 'Ezen sütik biztosítják a weboldal megfelelő működését, megkönnyítik annak használatát. ' +
              'Ezen sütik használata nélkül a weboldal egyes részei nem működnek megfelelően, ezért ezek a sütik nem ' +
              'kapcsolhatók ki.',
          },
          functional: {
            title: 'Funkcionális sütik',
            description: 'Ezek a sütik lehetővé teszik a weboldal megjelenésének személyre szabását az Ön egyedi ' +
              'beállításainak rögzítésével (a weboldal nyelve, a megjelenítendő keresési eredmények száma, süti ' +
              'beállítások stb.). Ezeknek a sütiknek a használatához az Ön hozzájárulása szükséges, de most, vagy ' +
              'bármikor módosíthatja a „beállításait”, ha megnyitja a beállításokat a sütikre vonatkozó {link}. A ' +
              'hozzájárulás megadásához első látogatáskor a csúszkát használhatja.',
          },
          targeting: {
            title: 'Személyre szabott hirdetések megjelenítéséhez szükséges sütik',
            description: 'Ezek a sütik az Ön böngészési szokásainak (pl. gyakran látogatott weboldalak, weboldalakon ' +
              'eltöltött idő, böngészési előzmények) alapján a felhasználók viselkedésére vonatkozó információkat ' +
              'tárolnak, amelyek lehetővé teszik az Ön számára személyre szabott hirdetések megjelenítését. Ezeknek ' +
              ' a sütiknek a használatához az Ön hozzájárulása szükséges, de most, vagy bármikor módosíthatja a ' +
              'beállításait, ha megnyitja a „beállításokat” a sütikre vonatkozó {link}. A hozzájárulás megadásához ' +
              'első látogatáskor a csúszkát használhatja.',
          },
        },
        name: 'Név',
        type: 'Típus',
        purpose: 'Cél és kezelt adatok',
        lifetime: 'Élettartam',
        third_party: 'Harmadik féltől származó?',
        yes: 'Igen',
        no: 'Nem',
        types: {
          session: 'Munkamenet',
          persistent: 'Állandó',
        },
        life: {
          year: '{count} év',
          month: '{count} hónap',
          day: '{count} nap',
          hour: '{count} óra',
          minute: '{count} perc',
          session: 'A felhasználói munkamenet befejezéséig',
          na: 'Nincs elérhető információe',
        },
        cookies: {
          'ajs_anonymous_id': 'Lehetővé teszi a Segment számára a felhasználók böngészőjének biztonságos azonosítását.',
          'test_cookie': 'This is a test cookie',
          'cookie_consent': 'A weboldal ennek a sütinek a segítségével tárolja a felhasználó sütikkel kapcsolatos beállításait.',
          'XSRF-TOKEN': 'A süti nem tartalmaz a felhasználóval kapcsolatos személyes adatot, segít azonban annak ellenőrzésében, hogy a munkamenet során nem történik-e a felhasználó beleegyezése nélküli változás (un. „eltérítés”) – például abban az esetben, ha egy weboldalon űrlapot kell elküldeni.',
          'NID': 'A felhasználó preferenciáinak és egyéb böngészési adatainak valamint egyedi azonosítójának tárolása, pl. előnyben részesített nyelv, keresési találatok oldalanként megjeleníteni kívánt száma (pl. 10 vagy 20) és az, hogy be van-e kapcsolva a Google Biztonságos Keresés szűrője.',
          '_GRECAPTCHA': 'Ezt a sütit a MOL a weboldal bejelentkező felületeire és az esetleg használt űrlapjaira helyezi el. Célja, hogy csökkentse annak kockázatát, hogy rossz szándékú felhasználók, valamint robotok „spammeljék” ezeket a felületeket és űrlapokat, például érvénytelen regisztrációkat hozzanak létre.',
          '_ga': 'A felhasználó látogatása céljának kiértékelése, és jelentések készítése a weboldalon végzett tevékenységről a böngészési élmény fejlesztése érdekében.',
          '_gid': 'Korábban eltárolt adatok frissítése, aktualizálása.',
          'OTZ': 'Korábbi keresések és oldallátogatások alapján személyre szabott hirdetések megjelenítése a Google webhelyein.',
          'GAD': 'Gemius reklám süti, molkupa.hu -hoz tartozik, a reklám banner (a weboldalon a reklám megjelenési felülete) betöltésére szolgál, amely kattintási méréseket végez.',
          'Joomla': 'Joomla nyelvkezelés.',
          'komadadditive_session': 'A weboldal működéséhez feltétlenül szükséges süti.',
          'laravel_session': 'A süti nem tartalmaz a felhasználóval kapcsolatos személyes adatot, azonban segíti a felhasználó biztonságos böngészését. Például: csökkenti annak a kockázatát, hogy harmadik fél a felhasználótól adatot lopjon, vagy a felhasználó nevében üzenetet küldjön.',
          'molgroup_session': 'A weboldal működéséhez feltétlenül szükséges süti.',
          'molgroupchemicals_session': 'A weboldal működéséhez feltétlenül szükséges süti.',
          'Gdyn': 'A süti célja, hogy védje a felhasználókat a felugró ablakok zavaró mértékű megjelenésével szemben – például segíti, hogy egyszerre csak egy felugró ablak jelenjen meg, a következő pedig csak egy bizonyos idő elteltével, így csökken a kockázata, hogy hirtelen a felhasználó számára megjelenik 3-4, vagy több felugró ablak.',
          '_utma': 'Felhasználók és a felhasználó böngészési munkamenetének (az időtartam, amíg a böngésző nyitva van) megkülönböztetése, a weboldal minden újabb megnyitása során frissül.',
          '_utmb': 'Felhasználók és a munkamenetek megkülönböztetése, a weboldal minden újabb megnyitása során frissül.',
          '_utmc': 'Korábban nem a weboldal látogatójaként nem azonosított, új felhasználó kiszűrése.',
          '_utmz': 'A felhasználó látogatása céljának kiértékelése, és jelentések készítése a weboldalon végzett tevékenységről a böngészési élmény fejlesztése érdekében.',
          '__gcl_ua': 'A célzott reklámhirdetések hatékonyságának mérése.',
          '1P_JAR': 'Reklámküldés és retargeting a felhasználó böngészési előzményei alapján.',
          'gdpr': 'A weboldal ennek a sütinek a segítségével tárolja a felhasználó sütikkel kapcsolatos beállításait.',
          '__fbp': 'Hirdetések megjelenítése a weboldalt meglátogató, böngészés közben Facebookra vagy Facebook Advertising által üzemeltetett digitális platformra bejelentkezett felhasználók számára.',
          'datr': 'A Facebookhoz csatlakozó böngésző azonosítása. Biztonsági célokra és a gyanús bejelentkezési tevékenységek, így különösen a szolgáltatáshoz hozzáférni próbáló robotok észlelésére szolgál.',
          'oo': 'Segít a felhasználóknak elutasítani a Meta hirdetések megjelenítését a harmadik fél webhelyein végzett tevékenységük alapján.',
          'sb': 'Lehetővé teszi a Meta számára a felhasználók böngészőjének biztonságos azonosítását.',
          'anj': 'Korábban elhelyezett cookie-azonosító felismerésével a visszatérő felhasználó eszközének azonosítása célzott hirdetések megjelenítése céljából.',
          'uuid2': 'A weboldal megnyitásához használt böngészők és eszközök megkülönböztetése. ',
        },
        third_parties: {
          gemius: 'Gemius SA Domaniewska utca 48., ép. D48, 6. em. Varsó, 02-672, 48 Lengyelország',
        },
      },
      cz: {
        cookies: {
          'ajs_anonymous_id': 'Allows Segment to securely identify user\'s browsers.',
          'Joomla': 'Správa jazyka Joomla. ',
          'komadadditive_session': 'Soubory cookies, které jsou nezbytně nutné pro fungování webových stránek.',
          'laravel_session': 'Soubor cookies neobsahuje žádné osobní údaje o uživateli, ale pomáhá uživateli bezpečně procházet stránky. Například: snižuje riziko, že třetí strana ukradne uživateli data nebo odešle zprávu jménem uživatele.',
          'molgroup_session': 'Soubory cookies, které jsou nezbytně nutné pro fungování webových stránek.',
          'molgroupchemicals_session': 'Soubory cookies, které jsou nezbytně nutné pro fungování webových stránek.',
          'XSRF-TOKEN': 'Soubor cookies neobsahuje žádné osobní údaje o uživateli, ale pomáhá kontrolovat, zda během relace nedochází ke změnám bez souhlasu uživatele (tzv. „únos“) – například když je třeba odeslat formulář na webové stránce.',
          'Gdyn': 'Účelem tohoto souboru cookies je chránit uživatele před rušivými vyskakovacími okny – pomáhá například zajistit, aby se najednou zobrazilo pouze jedno vyskakovací okno a další až po určité době, čímž se snižuje riziko, že se uživateli náhle zobrazí 3-4 nebo více vyskakovacích oken.',
          '_GRECAPTCHA': 'Tento soubor cookies umisťuje společnost MOL do přihlašovacího rozhraní webových stránek a do všech formulářů, které můžete použít. Jeho účelem je snížit riziko, že škodliví uživatelé a roboti budou tato rozhraní a formuláře „spamovat“, například vytvářením neplatných registrací.',
          '_ga': 'Vyhodnocování účelu návštěvy uživatele a vytváření zpráv o aktivitě na webových stránkách za účelem lepšího prohlížení.',
          '_gid': 'Aktualizace a obnovení dříve uložených dat.',
          '_utma': 'Rozlišování uživatelů a uživatelovy relace prohlížení (doba otevření prohlížeče), která se aktualizuje při každém otevření webové stránky.',
          '_utmb': 'Rozlišování mezi uživateli a relacemi, aktualizované při každém otevření webové stránky.',
          '_utmc': 'Filtrování nových uživatelů, kteří nebyli dříve identifikováni jako návštěvníci webových stránek.',
          '_utmz': 'Vyhodnocování účelu návštěvy uživatele a vytváření zpráv o aktivitě na webových stránkách za účelem lepšího prohlížení.',
          '__gcl_ua': 'Měření účinnosti cílené reklamy.',
          '1P_JAR': 'Reklama a cílení na základě historie procházení uživatele.',
          'cookie_consent': 'Ukládání informací o reklamách, které si uživatel potenciálně prohlédl před návštěvou webu.',
          'NID': 'Ukládání preferencí uživatele a dalších informací o prohlížení a jedinečného identifikátoru, jako je preferovaný jazyk, počet výsledků vyhledávání, které se mají zobrazit na jedné stránce (např. 10 nebo 20), a zda je povoleno filtrování Google Safe Search.',
          'OTZ': 'Zobrazování personalizovaných reklam na stránkách Google na základě předchozích vyhledávání a zobrazení stránek.',
          'GAD': 'Reklamní soubor cookies Gemius, patřící společnosti molkupa.hu, který se používá k načtení reklamního banneru (rozhraní na webových stránkách, kde se zobrazuje reklama), který měří kliknutí.',
          'gdpr': 'Webové stránky používají tento soubor cookies k uložení uživatelských preferencí souborů cookies.',
          '__fbp': 'Zobrazování reklam uživatelům, kteří navštíví webové stránky a jsou během prohlížení přihlášeni na Facebooku nebo digitální platformě, kterou provozuje Facebook Advertising.',
          'datr': 'Identifikace prohlížeče, který je připojen ke službě Facebook. Používá se pro účely zabezpečení a k odhalení podezřelých přihlašovacích aktivit, zejména robotů, kteří se snaží získat přístup ke službě.',
          'oo': 'Pomáhá uživatelům odhlásit se ze zobrazování reklam Meta na základě jejich aktivity na webových stránkách třetích stran.',
          'sb': 'Umožňuje společnosti Meta bezpečně identifikovat prohlížeče uživatelů.',
          'anj': 'Identifikace zařízení vracejícího se uživatele rozpoznáním dříve umístěného identifikátoru souboru cookies za účelem zobrazení cílených reklam.',
          'uuid2': 'Rozlišování mezi prohlížeči a zařízeními používanými k otevření webových stránek.',
        },
        third_parties: {
          gemius: 'Ano Gemius SA Domaniewska 48., budova D48, 6. patro Varšava, 02-672, 48 Polsko',
        },
      },
    },
  },
  props: {
    cookies: {
      type: Array,
    },
    functional: {
      type: Boolean,
    },
    targeting: {
      type: Boolean,
    },
  },
  data() {
    return {
      show: false,
      consent: {
        id: null,
        level: [NECESSARY],
      },
      expanded: {
        [NECESSARY]: false,
        [FUNCTIONAL]: false,
        [TARGETING]: false,
      },
    };
  },
  computed: {
    sections() {
      const sections = {[NECESSARY]: [], [FUNCTIONAL]: []};
      this.cookies.map((name) => {
        const found = this.availableCookies.find((a) => a.name === name);
        if (found) {
          sections[found.level] = sections[found.level] || [];
          sections[found.level].push(found);
        }
      });
      return sections;
    },
    availableCookies() {
      const session = this.$t('types.session');
      const persistent = this.$t('types.persistent');
      return [{
        level: TARGETING,
        name: 'ajs_anonymous_id',
        type: persistent,
        life: years,
        is_third_party: true,
      }, {
        level: TARGETING,
        name: 'cookie_consent',
        type: persistent,
        life: 2 * years,
        is_third_party: 'Google',
      }, {
        level: NECESSARY,
        name: 'XSRF-TOKEN',
        type: session,
        life: 2 * hours,
        is_third_party: false,
      }, {
        level: FUNCTIONAL,
        name: 'NID',
        type: persistent,
        life: 6 * months,
        is_third_party: 'Google',
      }, {
        level: FUNCTIONAL,
        name: '_GRECAPTCHA',
        type: persistent,
        life: 6 * months,
        is_third_party: 'Google reCAPTCHA',
      }, {
        level: TARGETING,
        name: '_ga',
        type: persistent,
        life: 2 * years,
        is_third_party: 'Google Analytics',
      }, {
        level: TARGETING,
        name: '_gid',
        type: persistent,
        _gid: 'Updating and refreshing previously stored data',
        life: 24 * hours,
        is_third_party: 'Google Analytics',
      }, {
        level: TARGETING,
        name: 'OTZ',
        type: persistent,
        life: 2 * years,
        is_third_party: 'Google',
      }, {
        level: TARGETING,
        name: 'GAD',
        type: persistent,
        life: years,
        is_third_party: this.$t('third_parties.gemius'),
      }, {
        level: NECESSARY,
        name: 'Joomla',
        type: session,
        life: 'session',
        is_third_party: false,
      }, {
        level: NECESSARY,
        name: 'komadadditive_session',
        type: session,
        life: 'session',
        is_third_party: false,
      }, {
        level: NECESSARY,
        name: 'laravel_session',
        type: session,
        life: 2 * hours,
        is_third_party: false,
      }, {
        level: NECESSARY,
        name: 'molgroup_session',
        type: session,
        life: 'session',
        is_third_party: false,
      }, {
        level: NECESSARY,
        name: 'molgroupchemicals_session',
        type: session,
        life: 'session',
        is_third_party: false,
      }, {
        level: FUNCTIONAL,
        name: 'Gdyn',
        type: persistent,
        life: 4 * years,
        is_third_party: 'Gemius SA Poland, Warsaw, 02-672, 48 Domaniewska street 48., building D48, 6. floor',
      }, {
        level: TARGETING,
        name: '_utma',
        type: persistent,
        life: 'na',
        is_third_party: 'Google Analytics',
      }, {
        level: TARGETING,
        name: '_utmb',
        type: session,
        life: 'session',
        is_third_party: 'Google Analytics',
      }, {
        level: TARGETING,
        name: '_utmc',
        type: session,
        life: 30 * minutes,
        is_third_party: 'Google Analytics',
      }, {
        level: TARGETING,
        name: '_utmz',
        type: persistent,
        life: 6 * months,
        is_third_party: 'Google Analytics',
      }, {
        level: TARGETING,
        name: '__gcl_ua',
        type: persistent,
        life: 3 * months,
        is_third_party: 'Google AdSense',
      }, {
        level: TARGETING,
        name: '1P_JAR',
        type: persistent,
        life: 30 * days,
        is_third_party: 'Google',
      }, {
        level: NECESSARY,
        name: 'gdpr',
        type: session,
        life: 'na',
        is_third_party: false,
      }, {
        level: TARGETING,
        name: '__fbp',
        type: persistent,
        life: 3 * months,
        is_third_party: 'Meta',
      }, {
        level: TARGETING,
        name: 'datr',
        type: persistent,
        life: 2 * years,
        is_third_party: 'Meta',
      }, {
        level: TARGETING,
        name: 'oo',
        type: persistent,
        life: 5 * years,
        is_third_party: 'Meta',
      }, {
        level: TARGETING,
        name: 'sb',
        type: persistent,
        life: 2 * years,
        is_third_party: 'Meta',
      }, {
        level: TARGETING,
        name: 'anj',
        type: persistent,
        life: 3 * months,
        is_third_party: 'adnxs.com',
      }, {
        level: TARGETING,
        name: 'uuid2',
        type: persistent,
        life: 3 * months,
        is_third_party: 'Xandr',
      }];
    },
    isAllowedFunctional() {
      return this.consent.level.includes(FUNCTIONAL);
    },
    isAllowedAds() {
      return this.consent.level.includes(TARGETING);
    },
  },
  created() {
    let show = this.$route.name !== 'cookie';
    window.cookieConsent = this;
    if (window.__PRERENDER_INJECTED) {
      show = false;
    } else {
      try {
        this.consent = JSON.parse(this.getCookie(CNAME));
        show = false;
        this.setThirdPartyConsents();
      } catch (e) {
        // Ignore
      }
    }
    this.$nextTick(() => {
      this.show = show;
    });
  },
  methods: {
    lifetime(time) {
      if (typeof time === 'number') {
        switch (0) {
          case time % years:
            return this.$tc('life.year', time / years, {count: time / years});
          case time % months:
            return this.$tc('life.month', time / months, {count: time / months});
          case time % days:
            return this.$tc('life.day', time / days, {count: time / days});
          default:
            return this.$tc('life.hour', time / hours, {count: time / hours});
        }
      }
      return this.$te(time) ? this.$t(time) : time;
    },
    changeConsent(section, value) {
      if (value && !this.consent.level.includes(section)) {
        this.consent.level.push(section);
      } else if (!value) {
        this.consent.level = this.consent.level.filter((s) => s !== section);
      }
    },
    update(selectedBtn) {
      this.updateConsentCookie();
      this.hide();
      this.setThirdPartyConsents();
      this.$emit('update:targeting', this.consent.level.includes(TARGETING));
      this.$emit('update:functional', this.consent.level.includes(FUNCTIONAL));
      this.sendToApi(selectedBtn);
    },
    setThirdPartyConsents() {
      if (window.gtag) {
        window.gtag('consent', 'update', {
          ad_storage: this.isAllowedAds ? 'granted' : 'denied',
          analytics_storage: this.isAllowedFunctional ? 'granted' : 'denied',
        });
        window.dataLayer.push({
          event: 'consent_updated', event_category: 'engagement',
          ad_storage: this.isAllowedAds ? 'granted' : 'denied',
          analytics_storage: this.isAllowedFunctional ? 'granted' : 'denied',
        });
      }
    },
    acceptAll() {
      this.consent.level = [NECESSARY, FUNCTIONAL, TARGETING];
      this.update(BTN_ALL);
    },
    rejectAll() {
      this.consent.level = [NECESSARY];
      this.update(BTN_REFUSE);
    },
    acceptSettings() {
      this.update(BTN_CUSTOM);
    },
    hide() {
      this.show = false;
    },
    getCookie(name) {
      const regex = new RegExp(`(^| )${name}=([^;]+)`);
      const match = document.cookie.match(regex);
      if (match) {
        return match[2];
      }
    },
    updateConsentCookie() {
      this.consent.id || (this.consent.id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = 16 * Math.random() | 0;
        return ('x' === c ? r : 3 & r | 8).toString(16);
      }));
      const cvalue = JSON.stringify(this.consent);
      const d = new Date;
      d.setTime(d.getTime() + 24 * SET_EXPIRE_DAY * 60 * 60 * 1e3);
      document.cookie = CNAME + '=' + cvalue + ';expires=' + d.toUTCString() + ';path=/';
    },
    async sendToApi(selectedBtn) {
      if (document.location.origin) {
        fetch(process.env.VUE_APP_COOKIE_CONSENT_API_URL, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          referrerPolicy: 'origin',
          body: JSON.stringify({
            btn: selectedBtn,
            domain: document.location.origin,
            ...this.consent,
          }),
        }).catch(err => {
          console.error('@sendToApi error # ', err);
        });
      }
    },
  },
};
