
/**
 * This is the site footer
 */
export default {
  name: 'Footer',
  i18n: {
    messages: {
      hu: {
        cgv: 'Fresh Corner Nyereményjáték Szabályzat',
        privacy: 'Fresh Corner Adatkezelési Tájékoztató',
        cookie: 'Fresh Corner Nyereményjáték Cookie Szabályzat',
      },
    },
  },
};
