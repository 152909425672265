import 'rewart-frontend-library/src/registerServiceWorker';

import minViewport from '@tofandel/min-viewport';
import rewartLib from 'rewart-frontend-library/src/main';
import supportsWebp from 'rewart-frontend-library/src/utils/supportsWebp';
import Vue from 'vue';
import VueGtag, {bootstrap} from 'vue-gtag';

import App from './App.vue';
import i18n, { switchLocale } from './plugins/i18n';
import routerFn from './plugins/router';
import store from './plugins/store';
import vuetify from './plugins/vuetify';
import routes from './routes';

const router = routerFn(routes);

Vue.use(VueGtag, {
  bootstrap: false,
  config: {id: process.env.VUE_APP_ANALYTICS},
}, router);

Vue.config.productionTip = false;

Vue.use(rewartLib, {
  vuetify,
  i18n,
  store,
  router,
});

minViewport(450);

const vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  data() {
    return {
      webp: true,
    };
  },
  created() {
    supportsWebp().catch(() => {
      this.webp = false;
      document.getElementsByTagName('html')[0].classList.add('nowebp');
    });
    if (!window.__PRERENDER_INJECTED) {
      const sc = document.getElementsByTagName('script')[0];


      window.dataLayer = window.dataLayer || [];
      window.gtag = (...args) => {
        window.dataLayer.push(args);
      };

      // Default ad_storage to 'denied'.
      window.gtag('consent', 'default', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
      });

      (function (w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer',process.env.VUE_APP_GTM);

      bootstrap();
    }
    router.onReady(() => {
      this.$nextTick(() => {
        this.$nextTick(() => {
          document.dispatchEvent(new Event('render-event'));
        });
      });
    });
  },
  render: h => h(App),
});

switchLocale().then(() => vue.$mount('#app'));
