const maybeReload = require('rewart-frontend-library/src/utils/maybeReload');

const routes = [
  {
    path: '/profilom/entry/:entry_id/address',
    redirect(route) {

      return '/entry/' + route.params.entry_id + '/address';
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => maybeReload(import(/* webpackChunkName: "home" */ './views/Home')),
  },
  {
    path: '/szabalyzat',
    name: 'game-rules',
    meta: {
      header: false,
      footer: false,
    },
    component: () => maybeReload(import(/* webpackChunkName: "game-rules" */ './views/GameRules')),
  },
  {
    path: '/entry/:entry_id/address',
    name: 'entry-address',
    meta: {
      title: 'Szállítási adatok megadása',
      auth: true,
    },
    component: () => maybeReload(import(/* webpackChunkName: "profile" */ './views/Address')),
  },
  {
    path: '/cookies',
    name: 'cookies',
    meta: {
      age: false,
    },
    component: () => maybeReload(import(/* webpackChunkName: "cookies" */ './views/Cookies')),
  },
  {
    path: '/media/:locale?/:file',
    beforeEnter(to, from, next) {
      try {
        window.location.href = require('@media/' + (to.params.locale ? to.params.locale + '/' : '') + to.params.file);
      } catch (e) {
        next({ name: '404', params: { 0: to.fullPath } });
      }
    },
  },
  {
    path: '*',
    name: '404',
    meta: {
      age: false,
    },
    component: () => maybeReload(import(/* webpackChunkName: "not-found" */ './views/NotFound')),
  },
];

module.exports = routes;
