// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Automatically generated, do not edit */
/* Automatically generated, do not edit */
.v-application .footer {
  padding: 15px 0 !important;
}
.v-application .footer a {
  height: 28px !important;
  margin-bottom: 0 !important;
  text-transform: none;
}
.v-application .footer a span {
  font-size: 16px !important;
}
@media screen and (max-width: 767px) {
.v-application .footer a span {
    font-size: 12px !important;
}
}
.v-application .footer .col {
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
}
.v-application .footer .v-btn {
  letter-spacing: normal;
  font-size: 20px !important;
  font-weight: normal !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/footer.scss"],"names":[],"mappings":"AAAA,yCAAyC;AACzC,yCAAyC;AACzC;EACE,0BAA0B;AAC5B;AACA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,oBAAoB;AACtB;AACA;EACE,0BAA0B;AAC5B;AACA;AACE;IACE,0BAA0B;AAC5B;AACF;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,sBAAsB;EACtB,0BAA0B;EAC1B,8BAA8B;AAChC","sourcesContent":["/* Automatically generated, do not edit */\n/* Automatically generated, do not edit */\n.v-application .footer {\n  padding: 15px 0 !important;\n}\n.v-application .footer a {\n  height: 28px !important;\n  margin-bottom: 0 !important;\n  text-transform: none;\n}\n.v-application .footer a span {\n  font-size: 16px !important;\n}\n@media screen and (max-width: 767px) {\n  .v-application .footer a span {\n    font-size: 12px !important;\n  }\n}\n.v-application .footer .col {\n  text-align: center;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n.v-application .footer .v-btn {\n  letter-spacing: normal;\n  font-size: 20px !important;\n  font-weight: normal !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
