
import { mdiChevronRight } from '@mdi/js';
import HamburgerMenu from 'rewart-frontend-library/src/components/HamburgerMenu';
import UsesPromoPage from 'rewart-frontend-library/src/mixins/UsesPromoPage';


/**
 * This is the site header
 */
export default {
  name: 'Header',
  components: { HamburgerMenu },
  mixins: [UsesPromoPage],
  i18n: {
    messages: {
      hu: {
        gameplay: 'Játék menete',
        prizes: 'Nyeremények',
        winners: 'Nyertesek',
        app: 'MOL Move',
        contact: 'Kapcsolat',
        register: 'Belépés/Regisztráció',
      },
    },
  },
  data() {
    return {
      mdiChevronRight,
      noSvg: false,
      showMobileNav: false,
      isHydrated: false,
      sections: {
        gameplay: '#jatek-menete',
        prizes: '#nyeremenyek',
        winners: '#nyertesek',
        app: '#mol-move',
        contact: '#kapcsolat',
      },
    };
  },
  computed: {
    breakpoint() { // just an example, could be one specific value if that's all you need
      return this.isHydrated
          ? this.$vuetify.breakpoint
          : {mobile: false};
    },
  },
  watch: {
    $route() {
      this.showMobileNav = false;
    },
  },
  mounted() {
    this.isHydrated = true;
    if (!window.__PRERENDER_INJECTED) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    }
  },
};
